import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'user-confirmed/:userid/:token',
    loadChildren: () => import('../modules/user-confirmed/user-confirmed.module').then(m => m.UserConfirmedModule)
  },
  {
    path: 'update_password/:userid/reset_pass/:token',
    loadChildren: () => import('../modules/change-password/change-password.module').then(m => m.ChangePasswordModule)
  },
  {
    path: 'update_pin/:userid/reset_pin/:token',
    loadChildren: () => import('../modules/reset-pin/reset-pin-routing.module').then(m => m.ResetPinRoutingModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('../modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: '**',
    loadChildren: () => import('../modules/home/home.module').then(m => m.HomeModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
