import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SimuladorComponent } from './modules/simulador/components/simulador.component';
import { PublicidadAppComponent } from './modules/publicidad-app/components/publicidad-app.component';
import { ContactoComponent } from './modules/contacto/components/contacto.component';
import { PreguntasFrecuentesComponent } from './modules/preguntas-frecuentes/components/preguntas-frecuentes.component';
import { CotizacionesComponent } from './modules/cotizaciones/components/cotizaciones.component';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { UserConfirmedComponent } from './modules/user-confirmed/components/user-confirmed.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { HomeComponent } from './modules/home/components/home.component';
import { HeaderComponent } from './core/header/header.component'
import { ChangePasswordComponent } from './modules/change-password/components/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './shared/guards/auth.guard';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { MainInterceptor } from './core/Interceptors/main.interceptor';
import { ResetPinComponent } from './modules/reset-pin/components/reset-pin.component';
import { SwiperDirective } from './shared/directives/swiper.directive';

// Swiper
import { register } from 'swiper/element/bundle';
import { RoleGuard } from './shared/guards/role.guard';
register();

@NgModule({
  declarations: [
    AppComponent,
    SimuladorComponent,
    PublicidadAppComponent,
    ContactoComponent,
    PreguntasFrecuentesComponent,
    CotizacionesComponent,
    UserConfirmedComponent,
    ChangePasswordComponent,
    HomeComponent,
    HeaderComponent,
    ResetPinComponent,
    SwiperDirective

  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgbTypeaheadModule
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
