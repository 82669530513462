import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url = environment.urlAuth;
  urlTicket = environment.urlTicketManager;

  constructor(
      private http: HttpClient
  ) { }

  loginDasboard(usuario, contraseña, codigo) {
      const path = this.url.concat('dashboard/login/')
      let body = {
        "username": usuario,
        "password": contraseña,
        "code": codigo
      }
      return this.http.post(path, body, { observe: 'response' });
  }

  loginCode(usuario, contraseña) {
    const path = this.url.concat('dashboard/code-login/')
    let body = {
      "username": usuario,
      "password": contraseña
    }
    return this.http.post(path, body, { observe: 'response' });
}

  loginTicket(usuario, contraseña) {
    const path = this.urlTicket.concat('users/login/')
    let body = {
      "username": usuario,
      "password": contraseña
    }
    return this.http.post(path, body, { observe: 'response' });
}

  get isStaff() {
    return localStorage.getItem('is_staff');
  }

  get isAdmin() {
    return localStorage.getItem('rol');
  }

  refreshToken(refresh){
    const path = this.url.concat('token/refresh/')
    let body = {
      "refresh": refresh,
    }
    return this.http.post(path, body, { observe: 'response' });
}
}
