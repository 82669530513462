import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { SpinnerService } from './core/Interceptors/spinner.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
  title = 'Universal Coins';

  @ViewChild('seccion', { static: false }) seccion: ElementRef;
  heigthWindows: any;
  timeout: any
  loading: boolean = false;

  constructor(
    private renderer2: Renderer2,
    private router: Router,
    private spinner: SpinnerService
  ) {
  }

  ngOnInit() {
    // this.cerrarSesionAutomatico();
    this.listenToLoading();
  }

  /**
 * Listen to the loadingSub property in the LoadingService class. This drives the
 * display of the loading spinner.
 */
  listenToLoading(): void {
    this.spinner.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  ngAfterViewInit(): void {
    this.heigthWindows = window.screen.height - 100;
    let secciones: any = document.getElementsByClassName('seccion');

    for (let i = 0; i < secciones.length; i++) {
      this.renderer2.setStyle(secciones[i], 'height', this.heigthWindows.toString().concat('px'))
    }
  };

  cerrarSesionAutomatico() {
    this.timeout = setInterval(() => {
      if (this.router.url.includes('dashboard')) {
        alert('Sesion expirada')
      }
      this.salir()
    }, 500000);
  }

  salir() {
    localStorage.clear()
    this.router.navigate(['dashboard/login']);
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  }

}
