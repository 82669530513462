<!-- <div id="main" class="scroll-container">
    <section>
        <app-simulador></app-simulador>
    </section>
    <section class="section2">
        <div>
            <span> Working on Tablets and Phones </span>
        </div>
    </section>
    <section class="section3">
        <app-publicidad-app></app-publicidad-app>
    </section>
    <section class="section4">
        <app-preguntas-frecuentes></app-preguntas-frecuentes>>
    </section>
    <section class="section5">
        <app-contacto></app-contacto>
    </section>
</div> -->

<!-- Loading spinner -->
<div *ngIf="loading" class="loading-container flex-content-center">
  <div class="spinner-border text-light" role="status" >
  </div>
</div>

<!-- Toaste message -->
<div id="toast-container" class="position-fixed top-0 start-50 translate-middle-x p-3" style="z-index: 1050;"></div>



<router-outlet></router-outlet>
