import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  show(message: string, type: 'success' | 'danger' | 'warning' | 'info' = 'info') {
    const toastContainer = document.getElementById('toast-container');
    if (!toastContainer) return;

    const toast = document.createElement('div');
    toast.className = `toast align-items-center text-white bg-${type} border-0 show`;
    toast.setAttribute('role', 'alert');
    toast.setAttribute('aria-live', 'assertive');
    toast.setAttribute('aria-atomic', 'true');

    toast.innerHTML = `
    <div class="d-flex">
      <div class="toast-body text-center fw-bold">${message}</div>
      <button type="button" class="btn-close btn-close-white m-auto" data-bs-dismiss="toast"></button>
    </div>`;

    toast.style.width = '500px'; // Ancho del toast
    toast.style.textAlign = 'center'; // Centrar el texto

    toastContainer.appendChild(toast);

    setTimeout(() => {
      toast.classList.remove('show');
      toastContainer.removeChild(toast);
    }, 3000);
  }

  showConfirm(message: string, onConfirm: () => void) {
    const toastContainer = document.getElementById('toast-container');
    if (!toastContainer) return;

    const toast = document.createElement('div');
    toast.className = `toast align-items-center text-white bg-warning border-0 show`;
    toast.setAttribute('role', 'alert');
    toast.setAttribute('aria-live', 'assertive');
    toast.setAttribute('aria-atomic', 'true');

    toast.innerHTML = `
      <div class="d-flex flex-column p-3">
        <div class="toast-body text-center fw-bold">${message}</div>
        <div class="d-flex justify-content-center mt-2">
          <button id="confirm-yes" class="btn btn-danger me-2">Sí</button>
          <button id="confirm-no" class="btn btn-secondary">No</button>
        </div>
      </div>`;

    toast.style.width = '500px'; // Ancho del toast
    toast.style.textAlign = 'center'; // Centrar el texto

    toastContainer.appendChild(toast);

    // Asignar eventos a los botones
    document.getElementById('confirm-yes')?.addEventListener('click', () => {
      onConfirm(); // Ejecuta la función de eliminación
      toastContainer.removeChild(toast);
    });

    document.getElementById('confirm-no')?.addEventListener('click', () => {
      toastContainer.removeChild(toast);
    });
  }
}
