import CryptoJS from "crypto-js";
const SECRET_KEY = "universa1CoinsW3b";

export function capitalizeText(value) {
  return value?.toLowerCase()
  .trim()
  .split(' ')
  .map( v => v[0].toUpperCase() + v.substr(1)).join(' ');
}

export function truncDecimal(x, posiciones = 0) {
  const s = x?.toString()
  const decimalLength = s?.indexOf('.') + 1
  const numStr = decimalLength != 0 ? s?.substr(0, decimalLength + posiciones) : s
  return Number(numStr)
}

export function truncDecimalEnCadena(x: number, posiciones: number = 8): string {
  if (x === undefined || x === null) return 'NaN'; // Manejo de valores inválidos

  const factor = Math.pow(10, posiciones);
  const truncated = Math.trunc(x * factor) / factor;

  // Convertimos a cadena con máximo `posiciones` decimales y eliminamos ceros no significativos
  return truncated.toFixed(posiciones).replace(/(\.\d*?[1-9])0+$/g, '$1').replace(/\.0+$/, '');
}


export const STATUS_USER: { [key: string]: string } = {
  "ACTIVE": "Activo",
  "VERIFIED": "Verificado",
  "BLOCKED": "Bloqueado",
  "REJECTED": "Rechazado",
  "SUSPENDED": "Suspendido",
  "MANUAL_REVIEW": "Revisión manual",
  "IN_PROGRESS": "En progreso",
  "PENDING_PEP": "Pendiente PEP",
  "PENDING_SO": "Pendiente SO",
  "PENDING_LEGAL": "Pendiente legal",
  "NON_VERIFIED": "No verificado",
  "DELETED": "Eliminado",
}

export const TRANSACTION_TYPE: { [key: string]: string } = {
  "BUY": "Compra",
  "SELL": "Venta",
  "CONVERT": "Conversión",
  "DEPOSIT": "Ingreso",
  "WITHDRAW": "Egreso",
  "EGRESO": "Egreso",
  "INGRESO": "Ingreso",
}

export const STATUS_TRANSACTION: { [key: string]: string } = {
  //CRYPTO
  "FINALIZADA": "Finalizada",
  "PROCESADA": "Finalizada",

  "PENDIENTE": "Pendiente",
  "BROADCASTING": "Pendiente",
  "CONVERTIDA": "Pendiente",
  "CONFIRMADA": "Pendiente",
  "PROCESANDO": "Pendiente",
  //"COBRADA": "Pendiente",

  "RECHAZADA": "Rechazada",
  "CANCELADA": "Rechazada",
  "BLOCKED": "Rechazada",
  "ERROR": "Rechazada",

  //PESOS
  "EJECUTADA": "Finalizada",

  "IN_PROGRESS": "Pendiente",
  "CREADA": "Pendiente",

  "REVERSANDO": "Rechazada",
  "REVERSADO": "Rechazada",
  "ERROR_REVERSO": "Rechazada",
  "CANCELED": "Rechazada"
};

// Función para encriptar
export function encryptString(text) {
return CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
}

// Función para desencriptar
export function decryptString(encryptedText) {
  const bytes = CryptoJS.AES.decrypt(encryptedText, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
}
