import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { decryptString } from '../funciones/funciones';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {

  rol: any

  constructor (
    private router: Router,
    private loginService: LoginService,
    private toastService: ToastService) {
      this.rol = decryptString(this.loginService.isAdmin)
    }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.rol != 'admin') {
      this.toastService.show('Acceso denegado: No tienes permiso para ver esta sección.', 'danger');
      this.router.navigate(['dashboard/home-dash']);
      return false;
    }
    return true;
  }
}
