import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';
import { LoginService } from '../services/login.service';

@Injectable()
export class MainInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private tokenAuth;
  private tokenRefreshAuth;

  constructor(
    private router: Router,
    public spinner: SpinnerService,
    private loginService: LoginService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.setLoading(true, request.url);
    // const tokenTicket: string = JSON.parse(localStorage.getItem('tokenTicket'));
    this.tokenAuth = JSON.parse(localStorage.getItem('tokenAuth'));
    this.tokenRefreshAuth = JSON.parse(localStorage.getItem('tokenRefreshAuth'));

    // if (tokenTicket && request.url.includes('ticket') && !request.url.includes('Criptomonedas') && !request.url.includes('update_password') && !request.url.includes('update_pin') && !request.url.includes('activate') && !request.url.includes('login')) {
    //   request = request.clone({
    //     setHeaders: {
    //       authorization: `Bearer ${ tokenTicket }`
    //     }
    //   });
    // }

    if (this.tokenAuth && !this.isExcludedUrl(request.url)) {
      request = this.addToken(request, this.tokenAuth);
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(err);
        }
      }),
      map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.spinner.setLoading(false, request.url);
        }
        return evt;
      }),
      finalize(() => {
        this.spinner.setLoading(false, request.url); // Detiene el spinner en cualquier caso
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private isExcludedUrl(url: string): boolean {
    return url.includes('ticket') ||
           url.includes('Criptomonedas') ||
           url.includes('update_password') ||
           url.includes('update_pin') ||
           url.includes('activate') ||
           url.includes('get-main-coins') ||
           url.includes('contact-support') ||
           url.includes('login');
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.loginService.refreshToken(this.tokenRefreshAuth).pipe(
        switchMap((res: any) => {
          this.isRefreshing = false;
          localStorage.setItem('tokenAuth', JSON.stringify(res.body.access));
          localStorage.setItem('tokenRefreshAuth', JSON.stringify(res.body.refresh));
          this.refreshTokenSubject.next(res.body.access);
          return next.handle(this.addToken(request, res.body.access));
        }),
        catchError((err) => {
          this.cleanUpOnUnauthorized(request.url);
          return throwError(err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        }),
        catchError(err => {
          this.cleanUpOnUnauthorized(request.url);
          return throwError(err);
        })
      );
    }
  }

  private cleanUpOnUnauthorized(url: string): void {
    this.isRefreshing = false;
    localStorage.clear(); // Limpia los tokens.
    this.spinner.setLoading(false, url); // Detiene el spinner.
    this.router.navigate(['dashboard/login']); // Redirige al login.
  }
}
